import React from "react";
import { useNavigate } from "react-router-dom";
import fran from "../../assets/fran.png";
import "./Home.css";

const HomeE = () => {
  const navigate = useNavigate();

  const handleNavigation = (url) => {
    navigate(`${url}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="fran-container">
        <img src={fran} alt="" />
        <div>
          <button
            className="fran-btn"
            onClick={() => handleNavigation("franchise")}
          >
            PRESS
          </button>
        </div>
      </div>
      <div
        className="bar"
        style={{ backgroundColor: "#31859b", color: "red" }}
      ></div>
    </>
  );
};

export default HomeE;
