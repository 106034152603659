import React from "react";
import babyhand from "../../assets/babyHand.png";
import homeImgB from "../../assets/homeImgB.png";
import "./Home.css";

const HomeA = () => {
  return (
    <>
      <div className="home-info-container">
        <div className="image-container">
          <img className="baby-hand-img" src={babyhand} alt="" />
        </div>
        <div>
          <div className="home-both-logo-container">
            <img src={homeImgB} alt="" />
          </div>
          <div
            style={{
              fontSize: "3rem",
              fontWeight: "600",
              textAlign: "center",
              color: "#632523",
            }}
          >
            Where
          </div>
          <div
            style={{
              fontSize: "4rem",
              fontWeight: "700",
              textAlign: "center",
              color: "#FF0000",
            }}
          >
            Kids get fun
          </div>
          <div
            style={{
              fontSize: "3rem",
              fontWeight: "600",
              textAlign: "center",
              color: "#0070C0",
            }}
          >
            While they Learn
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeA;
