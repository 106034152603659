import React from "react";
import { useNavigate } from "react-router-dom";
import "./Navbar.css";
import logo from "../../assets/logo.png";
import subLogo from "../../assets/subLogo.png";
const Navbar = () => {
  const navigate = useNavigate();

  const handleNavigation = (url) => {
    navigate(`${url}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="nav-container">
        <div>
          <div className="nav-logo " onClick={() => handleNavigation("")}>
            <img className="sdw" src={logo} alt="" />
          </div>
        </div>
        <div className="nav-buttons">
          <button
            onClick={() => handleNavigation("about")}
            className="primary-btn"
            style={{ backgroundColor: "#D805D8" }}
          >
            About Us
          </button>
          <button
            onClick={() => handleNavigation("system")}
            className="primary-btn"
            style={{ backgroundColor: "#578ACA" }}
          >
            Our System
          </button>
          <button
            onClick={() => handleNavigation("programs")}
            className="primary-btn"
            style={{ backgroundColor: "#05BCFE" }}
          >
            Our Programs
          </button>
          <button
            onClick={() => handleNavigation("parents")}
            className="primary-btn"
            style={{ backgroundColor: "#05BC57" }}
          >
            For Parents
          </button>
          <button
            onClick={() => handleNavigation("franchise")}
            className="primary-btn"
            style={{ backgroundColor: "#FFFF05", color: "black" }}
          >
            Franchise
          </button>
          <button
            onClick={() => handleNavigation("gallery")}
            className="primary-btn"
            style={{ backgroundColor: "#FFA405" }}
          >
            Gallery
          </button>
          <button
            onClick={() => handleNavigation("contact")}
            className="primary-btn"
            style={{ backgroundColor: "#FF3905" }}
          >
            Contact Us
          </button>
        </div>
        <div>
          <div className="nav-sub-logo ">
            <img className="sdw" src={subLogo} alt="" />
          </div>
        </div>
      </div>
      <div
        id="scroll-container"
        style={{
          backgroundColor: "#215968",
          color: "#FFFF00",
          overflow: "hidden",
          fontSize: "1.4rem",
          padding: "5px 0px",
          fontWeight: "600",
          marginBottom: "10px",
        }}
      >
        <marquee
          id="scroll-text"
          behavior="scroll"
          direction="left"
          scrollamount="10"
        >
          The Leader in World Class Early Education and Care at Unbelievably Low
          Cost
        </marquee>
      </div>
    </>
  );
};

export default Navbar;
