import React from "react";
import "./Franchise.css";
import franA from "../../../assets/franA.png";
import franB from "../../../assets/franB.png";

const Franchise = () => {
  return (
    <>
      <div className="fran-main">
        <img src={franA} alt="" />
        <img src={franB} alt="" />
      </div>
    </>
  );
};

export default Franchise;
