import React from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/pages/footer/Footer";
import About from "./components/pages/about/About";
import Home from "./components/home/Home";
import System from "./components/pages/system/System";
import Programs from "./components/pages/programs/Programs";
import Parents from "./components/pages/parents/Parents";
import Franchise from "./components/pages/franchise/Franchise";
import Gallery from "./components/pages/gallery/Gallery";
import Contact from "./components/pages/contact/Contact";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/about" element={<About />} />
        <Route path="/system" element={<System />} />
        <Route path="/programs" element={<Programs />} />
        <Route path="/parents" element={<Parents />} />
        <Route path="/franchise" element={<Franchise />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
