import React from "react";
import girl from "../../assets/girlHand.png";
import brand from "../../assets/brand.png";

import "./Home.css";

const HomeB = () => {
  return (
    <>
      <div
        style={{
          backgroundColor: "#2B4769",
          color: "#FFFF00",
          textAlign: "center",
          fontSize: "2rem",
          padding: "5px 0px",
          fontWeight: "700",
        }}
      >
        Most Popular Educational Brand
      </div>
      <div className="home-educational-brand">
        <div className="home-girl-img">
          <img src={girl} alt="" />
        </div>
        <div className="brand-section">
          <img src={brand} alt="" />
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#2B4769",
          color: "#FFFF00",
          textAlign: "center",
          fontSize: "2rem",
          padding: "5px 0px",
          fontWeight: "700",
        }}
      >
        Authentic and Trusted to all Parents
      </div>
    </>
  );
};

export default HomeB;
