import React from "react";
import admi from "../../assets/admi.png";
import { useNavigate } from "react-router-dom";

const HomeD = () => {
  const navigate = useNavigate();

  const handleNavigation = (url) => {
    navigate(`${url}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div
        className="bar"
        style={{ backgroundColor: "red", color: "red" }}
      ></div>
      <div className="admi-container">
        <img src={admi} alt="" />
      </div>
      <div className="admi-btn-container">
        <div>To Admit your Children</div>
        <button
          className="admi-btn"
          onClick={() => handleNavigation("parents")}
        >
          PRESS
        </button>
      </div>
      <div
        className="bar"
        style={{ backgroundColor: "#DF006F", color: "red" }}
      ></div>
    </>
  );
};

export default HomeD;
