import React from "react";
import { useNavigate } from "react-router-dom";
import "./Footer.css";
import logo from "../../../assets/logo.png";
import subLogo from "../../../assets/subLogo.png";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { MdAttachEmail } from "react-icons/md";

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigation = (url) => {
    navigate(`${url}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="footer-main">
        <div className="footer-head">
          <div className="footer-logo">
            <img src={logo} alt="" />
          </div>
          <div>
            Hotline
            <div className="footer-icon">
              <FaPhoneSquareAlt />
            </div>{" "}
            : <a href="tel:+917980134120">+91 79801 34120</a>
          </div>
          <div>
            <div className="footer-icon">
              <MdAttachEmail />
            </div>
            {" : "}
            <a href="mailto:care@kidstle.com">care@kidstle.com</a>
          </div>

          <div>
            <img src={subLogo} alt="" />
          </div>
        </div>
        <div className="footer-details">
          <div>
            <div className="footer-title">About KIDSTLE</div>
            <div>
              Happy children, caring teachers, engaging programs and exceptional
              facilities all combine to create an extraordinary place “ KIDSTLE
              “ for young children to learn and grow.
            </div>
          </div>
          <div>
            <div className="footer-title">Quick Links</div>
            <div className="fotter-btn">
              <button onClick={() => handleNavigation("")}>Home</button>
              <button onClick={() => handleNavigation("about")}>
                About Us
              </button>
              <button onClick={() => handleNavigation("system")}>
                Our System
              </button>
              <button onClick={() => handleNavigation("programs")}>
                Our Programs
              </button>
              <button onClick={() => handleNavigation("parents")}>
                For Parents
              </button>
              <button onClick={() => handleNavigation("franchise")}>
                Franchise
              </button>
              <button onClick={() => handleNavigation("gallery")}>
                Gallery
              </button>
              <button onClick={() => handleNavigation("contact")}>
                Contact Us
              </button>
            </div>
          </div>
          <div>
            <div className="footer-title">Address:</div>
            <div>
              <address>
                Kidstle World Preschool
                <br />
                Primerose Earlyeducation Group
                <br />
                1582, Sector H7
                <br />
                Gurugram, Haryana
                <br />
                <br />
                <a className="footer-link" href="tel:+917980134120">
                  Contact Us: +91 79801 34120
                </a>
                <br />
                <a
                  className="footer-link"
                  href="mailto:care@kidstle.com 
"
                >
                  Email: care@kidstle.com
                </a>
              </address>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
