import React from "react";
import "./Home.css";
import phil from "../../assets/phil.png";

import { useNavigate } from "react-router-dom";

const HomeC = () => {
  const navigate = useNavigate();

  const handleNavigation = (url) => {
    navigate(`${url}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div
        className="bar"
        style={{ backgroundColor: "red", color: "red" }}
      ></div>
      <div className="bar" style={{ backgroundColor: "yellow", color: "red" }}>
        WE FOLLOW
      </div>
      <div className="phil-container">
        <img src={phil} alt="" />
        <div className="phil-btn">
          <button onClick={() => handleNavigation("programs")}>
            MOST UPDATED EDUCATION PROGRAM
          </button>
        </div>
      </div>
      <div
        className="bar"
        style={{ backgroundColor: "yellow", color: "red" }}
      ></div>
    </>
  );
};

export default HomeC;
