import React from "react";
import "./Gallery.css";
import galA from "../../../assets/galA.png";
import galB from "../../../assets/galB.png";
const Gallery = () => {
  return (
    <>
      {" "}
      <div className="gal-main">
        <img src={galA} alt="" />
        <img src={galB} alt="" />
      </div>
    </>
  );
};

export default Gallery;
