import React from "react";
import "./About.css";
import aboutA from "../../../assets/aboutA.png";
import aboutB from "../../../assets/aboutB.png";
import aboutC from "../../../assets/aboutC.png";

const About = () => {
  return (
    <>
      <div className="about-container">
        <img src={aboutA} alt="" />
        <img src={aboutB} alt="" />
        <img src={aboutC} alt="" />
      </div>
    </>
  );
};

export default About;
