// import React, { useState, useEffect } from "react";
// import { motion, AnimatePresence } from "framer-motion";

// const AutoSlider = ({ components, interval = 3000 }) => {
//   const [index, setIndex] = useState(0);
//   const [isPaused, setIsPaused] = useState(false);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       if (!isPaused) {
//         setIndex((prevIndex) => (prevIndex + 1) % components.length);
//       }
//     }, interval);

//     return () => clearInterval(intervalId);
//   }, [components, interval, isPaused]);

//   const handleMouseEnter = () => {
//     setIsPaused(true);
//   };

//   const handleMouseLeave = () => {
//     setIsPaused(false);
//   };

//   return (
//     <div
//       style={{ width: "100%", height: "100%" }}
//       onMouseEnter={handleMouseEnter}
//       onMouseLeave={handleMouseLeave}
//     >
//       <AnimatePresence initial={false} mode="wait">
//         {components.map(
//           (Component, idx) =>
//             idx === index && (
//               <motion.div
//                 key={idx}
//                 style={{
//                   top: 0,
//                   left: 0,
//                   width: "100%",
//                   height: "100%",
//                 }}
//                 initial={{ opacity: 0, x: "10%" }}
//                 animate={{ opacity: 1, x: "0%" }}
//                 exit={{ opacity: 0, x: "-10%" }}
//                 transition={{ duration: 0.3 }}
//               >
//                 {Component}
//               </motion.div>
//             )
//         )}
//       </AnimatePresence>
//     </div>
//   );
// };

// export default AutoSlider;

import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const AutoSlider = ({ components, interval = 5000 }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % components.length);
    }, interval);

    return () => clearInterval(intervalId);
  }, [components, interval]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <AnimatePresence initial={false} mode="wait">
        {components.map(
          (Component, idx) =>
            idx === index && (
              <motion.div
                key={idx}
                style={{
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                initial={{ opacity: 0, x: "10%" }}
                animate={{ opacity: 1, x: "0%" }}
                exit={{ opacity: 0, x: "-10%" }}
                transition={{ duration: 0.3 }}
              >
                {Component}
              </motion.div>
            )
        )}
      </AnimatePresence>
    </div>
  );
};

export default AutoSlider;
