import React from "react";
import "./Contact.css";
import cont from "../../../assets/cont.png";

const Contact = () => {
  return (
    <>
      <div className="cont-main">
        <img src={cont} alt="" />
      </div>
    </>
  );
};

export default Contact;
