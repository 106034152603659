import React from "react";
import "./Home.css";
import HomeA from "./HomeA";
import HomeB from "./HomeB";
import HomeC from "./HomeC";
import HomeD from "./HomeD";
import HomeE from "./HomeE";
import AutoSlider from "./AutoSlider";

const Home = () => {
  const components = [<HomeA />, <HomeB />, <HomeC />, <HomeD />, <HomeE />];

  return (
    <>
      <div className="home-container">
        <AutoSlider components={components} />
      </div>
    </>
  );
};

export default Home;
