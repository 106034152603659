import React from "react";
import "./Programs.css";
import progA from "../../../assets/progA.png";
import progB from "../../../assets/progB.png";

const Programs = () => {
  return (
    <>
      <div className="prog-main">
        <img src={progA} alt="" />
        <img src={progB} alt="" />
      </div>
    </>
  );
};

export default Programs;
