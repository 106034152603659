import React from "react";
import "./Parents.css";
import praA from "../../../assets/praA.png";
import praB from "../../../assets/praB.png";

const Parents = () => {
  return (
    <>
      <div className="pra-main">
        <img src={praA} alt="" />
        <img src={praB} alt="" />
      </div>
    </>
  );
};

export default Parents;
