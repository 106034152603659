import React from "react";
import "./System.css";
import systemA from "../../../assets/systemA.png";
import systemB from "../../../assets/systemB.png";

const System = () => {
  return (
    <>
      <div className="system-main">
        <img src={systemA} alt="" />
        <img src={systemB} alt="" />
      </div>
    </>
  );
};

export default System;
